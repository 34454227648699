<template>
  <b-row>

    <h2 class="pl-1">Назначить администратора</h2>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--    EDIT    -->
            <modal-button
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="md"
              @btn-emit="updateAdmin(data.item.id)"
              @open-emit="getAdmin(data.item.id)"
              :name="`modal-edit-${data.item.id}`"
              modalButton="Сохранить"
              :id="data.item.id"
              modal-title="Редактировать"
              variant="flat-warning"
              ref="update-modal-button"
            >
              <template
                v-slot:button
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="21"
                />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap">

                    <!--   TITLE   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Название`" rules="required" v-slot="{errors}">
                        <b-form-group
                          label="Название"
                          label-for="name"
                        >
                          <b-form-input
                            v-model="name"
                            id="name"
                            size="lg"
                            placeholder="Название"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                  </div>
                </ValidationObserver>
              </template>

            </modal-button>

            <!--   DELETE   -->
            <!--            <modal-button-->
            <!--              class="crud__btn"-->
            <!--              ripple="rgba(113, 102, 240, 0.15)"-->
            <!--              size="sm"-->
            <!--              :name="`modal-delete-${data.item.id}`"-->
            <!--              modalButton="Удалить"-->
            <!--              modal-title="Удалить"-->
            <!--              variant="flat-danger"-->
            <!--              :id="data.item.id"-->
            <!--              ref="delete-modal"-->
            <!--              @btn-emit="deleteFeedback"-->
            <!--            >-->
            <!--              <template-->
            <!--                v-slot:button-->
            <!--              >-->
            <!--                <feather-icon-->
            <!--                  icon="Trash2Icon"-->
            <!--                  size="21"-->
            <!--                />-->
            <!--              </template>-->

            <!--              <template v-slot:modal-body>-->
            <!--                <div class="my-2">-->
            <!--                  Вы действительно хотите удалить эту Горячую линию?-->
            <!--                </div>-->
            <!--              </template>-->

            <!--            </modal-button>-->

          </div>
        </template>

      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
  BFormFile
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  name: "AdminsList",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    BFormFile,
    ModalButton,
    ToastificationContent,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      arrayNames: [],
      name: '',
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],

      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getAdmins()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  methods: {

    getAdmin(id) {
      let item = {}
      item = this.items.filter(item => item.id === id)
      this.name = item[0].name
    },

    deleteImage() {
      this.image = null
    },

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
        .catch(() => {
        })
    },

    clearInputs() {
      this.name = ''
    },

    async getAdmins() {
      this.isBusy = true
      await api.AdminsList.fetchAdmins()
        .then(res => {
          this.name = res.data.name
          this.items = res.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getOneAdmin() {
      this.isBusy = true
      await api.AdminsList.fetchOneAdmin()
        .then(res => {
          this.name = res.data.name
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    // updateFeedbackStatus(id) {
    //   this.isBusy = true
    //   api.feedbacks.updateFeedbackStatus(id)
    //     .then(res => {
    //       this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
    //     })
    //     .catch((error) => {
    //       console.error(error)
    //       this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
    //     })
    //     .finally(() => {
    //       this.getFeedbacks()
    //       this.isBusy = false
    //     })
    // },

    // async createNewAdmin() {
    //   const isValid = await this.$refs['validation-observer'].validate()
    //   if (isValid) {
    //
    //     const formData = new FormData()
    //     formData.append('name', this.name)
    //
    //     api.AdminsList.createNewAdmin(formData)
    //       .then(res => {
    //         this.$refs['create-modal-button'].closeModal()
    //         this.showToast('success', 'Успешно создано!', 'CheckIcon')
    //       })
    //       .catch((error) => {
    //         console.error(error)
    //         this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
    //       })
    //       .finally(() => {
    //         this.getAdmins()
    //         this.clearInputs()
    //       })
    //   }
    // },

    // async getOneFeedback(id) {
    //   await api.feedbacks.fetchOneFeedback(id)
    //     .then(res => {
    //       this.title.en = res.data.title.en
    //       this.title.ru = res.data.title.ru
    //       this.title.uz = res.data.title.uz
    //       this.position = res.data.position
    //     })
    //     .catch((error) => {
    //       console.error(error)
    //     })
    //     .finally(() => {
    //     })
    // },
    //
    async updateAdmin(id) {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const body = new FormData()
        body.append('name', this.name)
        api.AdminsList.updateAdmin(id, body)
          .then(res => {
            this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.$refs['update-modal-button'].closeModal()
            this.getAdmins()
            this.clearInputs()
          })
      }
    },
    //
    // deleteFeedback(id) {
    //   api.feedbacks.deleteFeedback(id)
    //     .then(res => {
    //       this.$refs["delete-modal"].closeModal()
    //       this.showToast('success', 'Успешно удалено!', 'CheckIcon')
    //     })
    //     .catch((error) => {
    //       console.error(error)
    //       this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
    //     })
    //     .finally(() => {
    //       this.getFeedbacks()
    //     })
    // },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

@import '@core/scss/vue/libs/vue-select.scss';

.delete_btn {
  position: relative;

  &::after {
    content: 'X';
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 10px;
    cursor: pointer;
    border: 1px solid #fff;
    color: #000000;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-img {
  max-width: 60px;
  max-height: 60px;

  & img {
    width: 100%;
    max-width: 60px;
    object-fit: contain;
  }
}

[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
